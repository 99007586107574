import React, { Suspense, lazy } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import {
  subscriptionPath,
  historyPath,
  recommendationPath,
  landingPath,
  promoLandingPath,
  englishLandingPath,
  liveStreamPath,
  liveChannelsPath,
  orderDevicePath,
  vodStreamPath,
  vodPath,
  vodShortPath,
  pollPath,
  articlePath,
  lpPath,
  reviewPath,
  contestPath
} from "./common/constants/paths";

const Subscription = lazy(() =>
  import("./subscription" /* webpackChunkName: "subscription" */)
);

const HistoryApp = lazy(() =>
  import("./vodHistory" /* webpackChunkName: "vod-history" */)
);

const RecommendationApp = lazy(() =>
  import("./vodRecommendation" /* webpackChunkName: "vod-recommendation" */)
);

const LandingApp = lazy(() =>
  import("./landing" /* webpackChunkName: "landing" */)
);

const PromoLanding = lazy(() =>
  import("./promo" /* webpackChunkName: "promo" */)
);

const EnglishLanding = lazy(() =>
  import("./english" /* webpackChunkName: "english" */)
);

const OrderDevice = lazy(() =>
  import("./orderDevice" /* webpackChunkName: "order-device" */)
);

const LiveChannels = lazy(() =>
  import("./live/channels" /* webpackChunkName: "live-channels" */)
);

const LiveStream = lazy(() =>
  import("./live/player" /* webpackChunkName: "live-stream" */)
);

const VodStream = lazy(() =>
  import("./vod/player" /* webpackChunkName: "vod-stream" */)
);

const VodApp = lazy(() => import("./vod" /* webpackChunkName: "vod" */));

const PollApp = lazy(() => import("./poll" /* webpackChunkName: "poll" */));

const ArticleApp = lazy(() =>
  import("./article" /* webpackChunkName: "article" */)
);

const Reviews = lazy(() =>
  import("./reviews" /* webpackChunkName: "reviews" */)
);
const Contest = lazy(() =>
  import("./contest" /* webpackChunkName: "contest" */)
);

export default function Main() {
  return (
    <Router>
      <Suspense fallback={""}>
        <Switch>
          <Route path={subscriptionPath} component={Subscription} />
          <Route path={historyPath} component={HistoryApp} />
          <Route path={recommendationPath} component={RecommendationApp} />
          <Route path={orderDevicePath} component={OrderDevice} />
          <Route exact path={landingPath} component={LandingApp} />
          <Route exact path={promoLandingPath} component={PromoLanding} />
          <Route path={englishLandingPath} component={EnglishLanding} />
          <Route exact path={liveChannelsPath} component={LiveChannels} />
          <Route exact path={liveStreamPath} component={LiveStream} />
          <Route exact path={vodStreamPath} component={VodStream} />
          <Route path={vodPath} component={VodApp} />
          <Route path={vodShortPath} component={VodApp} />
          <Route path={pollPath} component={PollApp} />
          <Route path={articlePath} component={ArticleApp} />
          <Route path={lpPath} component={ArticleApp} />
          <Route path={reviewPath} component={Reviews} />
          <Route path={contestPath} component={Contest} />
        </Switch>
      </Suspense>
    </Router>
  );
}
