import React from "react";
import ReactDOM from "react-dom";
import "core-js";
import "raf/polyfill";
import Main from "./Main";
// import Main from "./MainSync";
import ErrorBoundary from "./common/errors/ErrorBoundary";
import { GlobalErrorFallback } from "./common/errors/GlobalErrorFallback";

ReactDOM.render(
  <ErrorBoundary fallback={<GlobalErrorFallback />}>
    <Main />
  </ErrorBoundary>,
  document.getElementById("root")
);
