export const subscriptionPath = "/account/select-package";
export const successPath = "/account/select-package/success";
export const historyPath = "/history_view/";
export const recommendationPath = "/recommendations/personal";
export const landingPath = "/";
export const currentlyWatchedPath = "/currently-watched/";
export const topsAllStarsPath = "/tops/allstars/";
export const airedTodayPath = "/aired_today/";
export const factsAllPath = "/facts/searchresults/";
export const allCommentsPath = "/comments/all/";
export const signUpPath = "#signup";
export const signInPath = "#signin";
export const liveSchedulePAth = "/schedule/live-schedule/";
export const orderDevicePath = "/customer/order-device/";
export const promoLandingPath = "/promo/";
export const liveStreamPath = "/live/:channelSlug/:streamOffset";
export const liveChannelsPath = "/live/:channelSlug?";
export const helpTVUrl = "/helpTV/";
export const englishLandingPath = "/en/";

export const vodStreamPath = "/vod/:mediaId";
export const vodPath = "/tv/:tag-online/:slug/:mediaId/";
export const vodShortPath = "/tv/:slug/:mediaId/";
export const confirmResendPath = "/confirmation/resend";
export const contactPAth = "/contact";
export const pollPath = "/poll/:slug/";
export const articlePath = "/russian_tv_media/:slug/";
export const lpPath = "/landing_page/:slug/";
export const reviewPath = "/reviews/";
export const contestPath = "/contest/:slug/";

export const noWarPath = "/etvnet";
