import { PureComponent } from "react";
import * as Sentry from "@sentry/browser";
import { isProduction } from "../constants/env";

function configSentry() {
  if (isProduction) {
    Sentry.init({
      dsn: "https://aa7b12d6f9c549ef87d30e4788c654ff@sentry2.etvnet.com/6"
    });

    Sentry.configureScope(scope => {
      scope.setUser({ username: window.username });
    });
  }
}

// configSentry();

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidUpdate() {
    if (this.props.error && !this.state.hasError) {
      this.setState({ hasError: true });
    }
  }

  componentDidCatch(error, info) {
    // Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
