import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

export function GlobalErrorFallback() {
  function handleClick() {
    window.location.reload();
  }

  return (
    <Container maxWidth="md" style={{ textAlign: "center", marginTop: "15%" }}>
      <Typography variant="h4">
        Ой, кажется у нас что-то пошло не так
      </Typography>
      <Typography style={{ marginTop: 30 }} variant="h5">
        {" "}
        Наша команда разработчиков будет оповещена об этой проблеме
      </Typography>
      <Button
        style={{ marginTop: 30 }}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Перезагрузить страницу
      </Button>
    </Container>
  );
}
